import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import Nav from "./nav"

const StyledLink = styled(Link)`
  ${tw`hover:text-opacity-75 bg-transparent hover:bg-transparent hover:text-gray-700`}
  box-shadow: none;
  color: inherit;
`

const StyledHeader = styled.header`
  ${tw`flex items-center justify-between flex-wrap p-6`}
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`

const LogoContainer = styled.div`
  ${tw`flex flex-grow items-center flex-shrink-0 mr-6`}
`

const Logo = styled.h1`
  ${tw`mt-0 mb-0 tracking-tighter xs:text-2xl md:text-3xl`}
`

const Header = ({ title }) => {
  return (
    <React.Fragment>
      <StyledHeader>
        <LogoContainer>
          <Logo>
            <StyledLink
              to={`/`}
            >
              {title}
            </StyledLink>
          </Logo>
        </LogoContainer>
        <Nav />
      </StyledHeader>
    </React.Fragment>
  )
}

export default Header
