import React, { useState } from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"

const StyledHamburgerContainer = styled.div`
  ${tw`block lg:hidden`}
`

const StyledHamburgerButton = styled.button`
  ${tw`flex items-center inline-block border-none cursor-pointer px-3 py-2 m-0 focus:outline-custom hover:text-opacity-75 bg-transparent hover:bg-transparent hover:text-black`}
  box-shadow: none;
  color: inherit;
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${tw`fill-current h-3 w-3`}
`
const StyledNav = styled.nav`
  ${tw`w-full block flex-shrink lg:flex lg:items-center lg:w-auto`}
`

const StyledLinkContainer = styled.div`
  ${tw`flex flex-col lg:flex-row lg:flex-grow`}
`

const StyledLink = styled(Link)`
  ${tw`hover:text-blue-700`}
`

const Nav = () => {
  const [hamburgerAria, setHamburgerAria] = useState(false)
  const [hamburgerActive, setHamburgerActive] = useState(false)

  const toggle = () => {
    setHamburgerAria(hamburgerAria === false ? true : false)
    setHamburgerActive(hamburgerActive === false ? true : false)
  }

  return (
    <React.Fragment>
      <StyledHamburgerContainer>
        <StyledHamburgerButton aria-label="menu" aria-controls="menu" aria-expanded={hamburgerAria} onClick={toggle}>
          <StyledFontAwesomeIcon icon={hamburgerActive ? faTimes : faBars} />
        </StyledHamburgerButton>
      </StyledHamburgerContainer>
      <StyledNav>
        <StyledLinkContainer>
          <StyledLink
            css={[
              tw`inline-block mt-4 lg:inline-block lg:mt-0 mr-4`,
              `box-shadow: none;`,
              `color: inherit;`,
              !hamburgerActive && tw`hidden`
            ]}
            to={'/'}
          >
            Home
          </StyledLink>
          <StyledLink
            css={[
              tw`inline-block mt-4 lg:inline-block lg:mt-0 mr-4`,
              `box-shadow: none;`,
              `color: inherit;`,
              !hamburgerActive && tw`hidden`
            ]}
            to={'/'}
          >
            About
          </StyledLink>
        </StyledLinkContainer>
      </StyledNav>
    </React.Fragment>
  )
}

export default Nav
