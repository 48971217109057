import React from "react"
import tw, { styled } from "twin.macro"

import Header from "./header"
import Footer from "./footer"

const StyledLayoutContainer = styled.div`
  ${tw`flex flex-col min-h-screen`}
`

const StyledMain = styled.div`
  ${tw`mx-auto my-0 flex-grow`}
  width: 100%;
  max-width: 1088px;
`

const Layout = ({ title, children }) => {
  return (
    <StyledLayoutContainer>
      <Header title={title}/>
      <StyledMain>
        {children}
      </StyledMain>
      <Footer />
    </StyledLayoutContainer>
  )
}

export default Layout
