import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagramSquare } from "@fortawesome/free-brands-svg-icons"
import { faRssSquare } from "@fortawesome/free-solid-svg-icons"

const StyledFooter = styled.footer`
  ${tw`flex flex-col relative bottom-0 left-0 w-full text-center p-6 mt-5`}
  background: #f9f9f9;
`

const StyledLink = styled(Link)`
  ${tw`cursor-pointer ml-2 text-gray-600 hover:text-gray-600 hover:text-opacity-75 bg-transparent hover:bg-transparent`}
  box-shadow: none;
`

const StyledA = styled.a`
  ${tw`cursor-pointer mr-2 text-gray-600 hover:text-gray-600 hover:text-opacity-75 bg-transparent hover:bg-transparent`}
  box-shadow: none;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  ${tw`text-4xl`}
`

const StyledTextContainer = styled.div`
  ${tw`mt-2 text-sm text-gray-600`}
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          social {
            instagram
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata
  return (
    <StyledFooter>
      <div>
        <StyledA href={`https://instagram.com/${social.instagram}`} target="_blank" rel="noopener noreferrer">
          <StyledFontAwesomeIcon icon={faInstagramSquare} />
        </StyledA>
        <StyledLink to={'/rss.xml'}>
          <StyledFontAwesomeIcon icon={faRssSquare} />
        </StyledLink>
      </div>
      <StyledTextContainer>
        © {new Date().getFullYear()}, WS Franklin
      </StyledTextContainer>
    </StyledFooter>
  )
}

export default Footer
